import React, { useState } from "react";
import { MUI, Button, SideIconButton } from "@amps/material-ui";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import MemberProfile from "components/shared/MemberProfile";
import useStyles from "components/DashboardPage/styles/NewRequestModal.style";
import { selectTheme } from "containers/FormikReactSelect";
import ClientModel from "models/client.model";
import GroupModel from "models/group.model";
import MemberModel from "models/member.model";
import ProductModel from "models/product.model";
import { MemberSearchData } from "types";
import FormikTextField from "containers/FormikTextField";
import TextMaskField from "containers/TextMaskField";
import MemberSearchTable from "components/DashboardPage/MemberSearchTable";
import { useStoreState, useStoreActions } from "redux/reducers/hooks";
import { Search } from "@material-ui/icons";
import { Modals } from "utils/modalUtils";
import MemberRequests from "components/RequestPage/MemberRequests";
import PlanNotes from "components/RequestPage/PlanNotes";
import { PlanNoteModel } from "models/planNote.model";

interface NewRequestFormValues {
  goal: string;
}

interface SearchFormValues {
  lastName: string;
  firstName: string;
  memberId: string;
}

const NewRequestFormSchema = Yup.object().shape({
  goal: Yup.string()
    .required("Required")
    .max(100, "Must be 100 characters or less")
});

const alphaMask = /[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒáéíñóúüÁÉÍÑÓÚÜ.'-]/; // A-Z, accented characters for Spanish and French, period, apostrophe, dash
const alphaNumericMask = /[a-zA-Z0-9-]/; // A-Z, 0-9, dash

export const getGroupLabel = (group: GroupModel) => `${group.code} - ${group.name}`;

export function NewRequestModal() {
  const [selectedType, setSelectedType] = useState("");
  const [selectedClient, setSelectedClient] = useState(null as ClientModel | null);
  const [selectedGroup, setSelectedGroup] = useState(null as GroupModel | null);
  const [selectedMember, setSelectedMember] = useState(null as MemberModel | null);
  const [groups, setGroups] = useState([] as GroupModel[]);
  const [members, setMembers] = useState(null as MemberModel[] | null);
  const [products, setProducts] = useState([] as ProductModel[]);
  const [note, setNote] = useState([] as PlanNoteModel[]);
  const [isSearching, setIsSearching] = useState(false);

  const clients = useStoreState(state => state.client.clients);
  const {
    group: { getGroupsByClientId },
    member: { getMembers, getMemberById },
    product: { getProductsByGroupId },
    modal: { openModal, closeModal }
  } = useStoreActions(actions => actions);

  const classes = useStyles();

  function handleAlignment(event: any, newType: string) {
    setSelectedType(newType);
  }

  async function handleSelectClient(value: any) {
    setSelectedClient(value);
    setSelectedGroup(null);
    setSelectedMember(null);
    setMembers(null);

    if (!value) {
      setGroups([]);
      return null;
    }

    const groups = await getGroupsByClientId(value.id);
    setGroups(groups);
  }

  async function handleSelectGroup(value: any) {
    setSelectedGroup(value);
    setSelectedMember(null);
    setProducts([]);
    setMembers(null);

    const data = await getProductsByGroupId(value.id);
    setProducts(data.products);
    setNote(data.planNotes);
  }

  async function onSelectMember(member: MemberModel) {
    const memberWithEligibility = await getMemberById(member.id);

    setSelectedMember(memberWithEligibility);
  }

  const handleMembersSearch = async (values: SearchFormValues) => {
    if (!selectedGroup) return;

    setIsSearching(true);
    setMembers(null);
    setSelectedMember(null);

    try {
      const { firstName, lastName, memberId } = values;
      const data: MemberSearchData = {};

      firstName && (data.firstName = firstName);
      lastName && (data.lastName = lastName);
      memberId && (data.ssn = memberId);
      selectedGroup && (data.clientGroupId = parseInt(selectedGroup.id));

      const members = await getMembers(data);

      setMembers(members);
    } finally {
      setIsSearching(false);
    }
  };

  const isFormValid = (isFormikFormValid: boolean) => {
    return !!selectedType && !!selectedClient && !!selectedGroup && !!selectedMember && isFormikFormValid;
  };

  const handleNewRequest = async (values: NewRequestFormValues) => {
    if (!selectedMember) {
      return;
    }

    const request: any = {
      firstInteraction: selectedType,
      memberId: selectedMember.id,
      goal: values.goal,
      clientId: selectedClient ? parseInt(selectedClient.id) : null,
      clientGroupId: selectedGroup ? parseInt(selectedGroup.id) : null
    };

    closeModal(Modals.NewRequest);
    openModal({
      modalType: Modals.NewService,
      modalProps: { request, member: selectedMember }
    });
  };

  const onClose = () => {
    openModal({
      modalType: Modals.Confirmation,
      modalProps: {
        onOk: handleConfirmButtonClick
      }
    });
  };

  const handleConfirmButtonClick = () => {
    closeModal(Modals.NewRequest);
  };

  return (
    <MUI.Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth={true}
      disableBackdropClick
    >
      <Formik
        initialValues={{
          goal: ""
        }}
        validationSchema={NewRequestFormSchema}
        onSubmit={handleNewRequest}
      >
        {requestForm => (
          <>
            <MUI.DialogContent className={classes.dialogPaper}>
              <div className={classes.row}>
                <MUI.Typography variant="h6" className={classes.dialogTitle}>
                  New Request
                </MUI.Typography>
              </div>
              <div className={classes.row}>
                <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                  How were you contacted?
                </MUI.Typography>
                <ToggleButtonGroup value={selectedType} exclusive onChange={handleAlignment} size="small">
                  <ToggleButton
                    value="Phone"
                    classes={{
                      selected: classes.toogleButton
                    }}
                  >
                    <span>Phone</span>
                  </ToggleButton>
                  <ToggleButton
                    value="Email"
                    classes={{
                      selected: classes.toogleButton
                    }}
                  >
                    <span>Email</span>
                  </ToggleButton>
                  <ToggleButton
                    value="AmpsConnect"
                    classes={{
                      selected: classes.toogleButton
                    }}
                  >
                    <span>Amps Connect</span>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className={classes.row}>
                <MUI.Grid container spacing={3}>
                  <MUI.Grid item xs={6}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                      <span>Client</span>
                    </MUI.Typography>
                    <Select
                      options={clients}
                      getOptionLabel={(option: ClientModel) => `${option.code} - ${option.name}`}
                      getOptionValue={(option: ClientModel) => option.id}
                      onChange={handleSelectClient}
                      value={selectedClient}
                      theme={selectTheme}
                      maxMenuHeight={140}
                    />
                  </MUI.Grid>
                  <MUI.Grid item xs={6}>
                    <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                      <span>Group</span>
                    </MUI.Typography>
                    <Select
                      options={groups}
                      getOptionLabel={(option: GroupModel) => getGroupLabel(option)}
                      getOptionValue={(option: GroupModel) => option.id}
                      onChange={handleSelectGroup}
                      value={selectedGroup}
                      isDisabled={!selectedClient}
                      theme={selectTheme}
                      maxMenuHeight={140}
                    />
                  </MUI.Grid>
                </MUI.Grid>
              </div>
              <div className={classes.row}>
                <Formik
                  initialValues={{
                    lastName: "",
                    firstName: "",
                    memberId: ""
                  }}
                  onSubmit={handleMembersSearch}
                >
                  {searchForm => (
                    <Form>
                      <MUI.Grid container spacing={3}>
                        <MUI.Grid item className={classes.gridItem}>
                          <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                            Last Name
                          </MUI.Typography>
                          <Field
                            name="lastName"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.textField
                              },
                              inputComponent: TextMaskField
                            }}
                            // eslint-disable-next-line
                            inputProps={{
                              mask: alphaMask
                            }}
                          />
                        </MUI.Grid>
                        <MUI.Grid item className={classes.gridItem}>
                          <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                            First Name
                          </MUI.Typography>
                          <Field
                            name="firstName"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.textField
                              },
                              inputComponent: TextMaskField
                            }}
                            // eslint-disable-next-line
                            inputProps={{
                              mask: alphaMask
                            }}
                          />
                        </MUI.Grid>
                        <MUI.Grid item className={classes.gridItem}>
                          <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                            Member ID
                          </MUI.Typography>
                          <Field
                            name="memberId"
                            component={FormikTextField}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.textField
                              },
                              inputComponent: TextMaskField
                            }}
                            // eslint-disable-next-line
                            inputProps={{
                              mask: alphaNumericMask
                            }}
                          />
                        </MUI.Grid>
                        <MUI.Grid item xs={2}>
                          <div className={classes.progressWrapper}>
                            <SideIconButton
                              variant="contained"
                              className={classes.searchButton}
                              Icon={Search}
                              align="left"
                              type="submit"
                              color="primary"
                              autoFocus
                              disabled={!searchForm.isValid || !selectedGroup || isSearching}
                              fullWidth
                            >
                              Search
                            </SideIconButton>
                            {isSearching && <MUI.CircularProgress size={24} className={classes.buttonProgress} />}
                          </div>
                        </MUI.Grid>
                      </MUI.Grid>
                    </Form>
                  )}
                </Formik>
              </div>
              <MemberSearchTable members={members} onSelectMember={onSelectMember} />
              {selectedClient && selectedGroup && selectedMember && (
                <div className={classes.row}>
                  <MemberRequests currentRequestId={-1} member={selectedMember} />
                </div>
              )}
              {selectedClient && selectedGroup && selectedMember && (
                <div className={classes.row}>
                  <MemberProfile
                    member={selectedMember}
                    client={selectedClient}
                    group={{
                      ...selectedGroup,
                      name: `${selectedGroup.name}${selectedGroup.isVip ? "" : ""}`
                    }}
                    products={products}
                    showPreCallAlert={true}
                  />
                </div>
              )}
              {selectedClient && selectedGroup && selectedMember && (
                <div className={classes.row}>
                  <PlanNotes planNotes={note} isLoading={false} />
                </div>
              )}

              <div className={classes.row}>
                <Form>
                  <MUI.Typography variant="body2" color="textSecondary" className={classes.rowTitle}>
                    <b>Request Goal:</b> Why is the member requesting service?
                  </MUI.Typography>

                  <Field
                    name="goal"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.textField
                      }
                    }}
                  />
                </Form>
              </div>
            </MUI.DialogContent>
            <MUI.DialogActions>
              <Button id="cancel-request-button" onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => requestForm.handleSubmit()}
                variant={isFormValid(requestForm.isValid) ? "contained" : "text"}
                color="primary"
                autoFocus
                disabled={!isFormValid(requestForm.isValid)}
              >
                Continue
              </Button>
            </MUI.DialogActions>
          </>
        )}
      </Formik>
    </MUI.Dialog>
  );
}

export default NewRequestModal;
